import "../styles/home.scss";
import { useNavigate, useOutletContext } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RESET_ACTION, RootState } from "../redux/ReduxStore";
import { AuthThunks } from "../redux/auth/AuthThunk";
import { NavbarContextType } from "../structure/NavbarStructure";
import { BackButtonFlex } from "../parts/BackButton";
export function SettingsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const userInfos = useSelector((state: RootState) => state.auth.user);

  const { helpPopup, setHelpPopup } = useOutletContext<NavbarContextType>();

  if (helpPopup === null || helpPopup === undefined) {
    setHelpPopup({
      node: (
        <>
          <h2>Menu paramètre</h2>
          <p>Retrouvez ici les paramètres de votre compte</p>
        </>
      ),
    });
  }

  const navigate = useNavigate();
  return (
    <>
      <div className="columns">
        <div className="is-fullwidth column">
          <Link
            to="/home/legal"
            className="button is-medium is-fullwidth button-cartel-light"
          >
            Mentions Légales
          </Link>
        </div>
      </div>
      <div className="columns">
        <div className="is-fullwidth column">
          <Link
            to="/home/cgu"
            className="button is-medium is-fullwidth button-cartel-light"
          >
            Conditions Générales d'Utilisation
          </Link>
        </div>
      </div>

      <div className="columns">
        <div className="is-fullwidth column">
          <Link
            to="/home/cgv"
            className="button is-medium is-fullwidth button-cartel-light"
          >
            Conditions Générales de Ventes
          </Link>
        </div>
      </div>
      {userInfos?.admin ? (
        <div className="columns">
          <div className="is-fullwidth column">
            <Link
              to="/admin"
              className="button is-medium is-fullwidth button-cartel-light button-cartel-admin"
            >
              Administration
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className="columns"
        style={{ flexGrow: 1, alignItems: "flex-end", marginBottom: "1rem" }}
      >
        <div className="is-fullwidth column">
          <button
            className="button is-medium is-fullwidth button-cartel-light"
            onClick={(e) => {
              e.preventDefault();
              dispatch(RESET_ACTION);
              dispatch(AuthThunks.logout());
              navigate("/");
            }}
          >
            Se déconnecter
          </button>
        </div>
      </div>
      <BackButtonFlex />
    </>
  );
}
