import { Link } from "react-router-dom";
import Logo from "../imgs/logo.png";
import { useState } from "react";

export function Navbar({ helpPopup }: { helpPopup: React.ReactNode }) {
  const [popupVisible, setPopupVisible] = useState(false);

  const crossSize = "2.5rem";
  const sizeStyle = (size: string | number): React.CSSProperties => {
    return {
      height: size,
      width: size,
      minWidth: size,
      minHeight: size,
      maxHeight: size,
      maxWidth: size,
    };
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <img
          src={Logo}
          alt="Logo de la compagnie"
          className="navbar-item"
          style={{ objectFit: "contain" }}
        />
        <Link to="/home" className="navbar-item">
          La Compagnie Vous Ment
        </Link>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            {helpPopup !== null ? (
              <>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setPopupVisible(!popupVisible);
                  }}
                  className="button navbar-button is-rounded"
                  style={{
                    fontWeight: 800,
                  }}
                >
                  ?
                </button>
                <div className={"modal " + (popupVisible ? "is-active" : "")}>
                  <div
                    className="modal-background"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupVisible(!popupVisible);
                    }}
                  ></div>
                  <div className="modal-content">
                    <div className="box">{helpPopup}</div>
                  </div>
                  <button
                    className="modal-close button is-rounded navbar-button"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupVisible(!popupVisible);
                    }}
                    style={{
                      fontWeight: 800,
                      ...sizeStyle(crossSize),
                    }}
                  ></button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
